import React from "react"
import Layout from "@components/layout"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Container from "@components/layout/container"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Anchor from "@components/elements/anchor"
import List from "@components/elements/list"

const LegalPage = () => {
  return (
    <Layout
      title={`Legal`}
      description={`At Klipfolio, we believe deeply in the benefits of building mutual trust in business relations. Confidence in the fulfilment of obligations, the predictability of actions, the understanding of risk, and the fair negotiation of value are fundamental characteristics that influence how we do business.`}
      fullWidth
      marginless
      hideOverflow
    >
      <Container fullWidth noPadding>
        <Flex gap="6rem" margin="10rem 0 6rem">
          <Container text>
            <Flex gap="2rem">
              <Heading as="h1" margin="0 0 1rem">
                The foundation of successful business relationships.
              </Heading>
              <Paragraph>
                At Klipfolio, we believe deeply in the benefits of building
                mutual trust in business relations. Confidence in the fulfilment
                of obligations, the predictability of actions, the understanding
                of risk, and the fair negotiation of value are fundamental
                characteristics that influence how we do business.
              </Paragraph>
              <Paragraph>
                The more we can help you by sharing information about systems,
                security threats, and our policies, the more closely we will be
                able to work together and build trust.
              </Paragraph>
            </Flex>
          </Container>
          <Flex background="#f3f5fc" padding="6rem 0">
            <Container>
              <Heading as="h2" margin="0 0 2rem">
                In this section you will find:
              </Heading>
              <Grid
                gap="2rem"
                columns="repeat(3, 1fr)"
                columnsMd="repeat(2, 1fr)"
                columnsSm="1fr"
              >
                <Flex
                  gap="1rem"
                  borderRadius="0.5rem"
                  background="white"
                  padding="2rem"
                >
                  <Heading as="h4">Legal</Heading>
                  <List gap="0" margin="0 0 0 2rem" as="ul">
                    <li>
                      <Anchor link="/legal/terms-of-service">
                        Terms of Service
                      </Anchor>
                    </li>
                    <li>
                      <Anchor link="/legal/privacy">Privacy Statement</Anchor>
                    </li>
                    <li>
                      <Anchor link="/legal/dpa">
                        Klipfolio Data Processing Addendum
                      </Anchor>
                    </li>
                    <li>
                      <Anchor link="/legal/ai-data-processing">
                        PowerMetrics AI Data Processing Addendum
                      </Anchor>
                    </li>
                    <li>
                      <Anchor link="/legal/solution-provider-partner-agreement">
                        Solution Provider Partner Agreement
                      </Anchor>
                    </li>
                    <li>
                      <Anchor link="/legal/trademarks">Trademarks</Anchor>
                    </li>
                  </List>
                </Flex>
                <Flex
                  gap="1rem"
                  borderRadius="0.5rem"
                  background="white"
                  padding="2rem"
                >
                  <Heading as="h4">Security</Heading>
                  <List gap="0" margin="0 0 0 2rem" as="ul">
                    <li>
                      <Anchor link="/legal/security">Security</Anchor>
                    </li>
                  </List>
                </Flex>
                <Flex
                  gap="1rem"
                  borderRadius="0.5rem"
                  background="white"
                  padding="2rem"
                >
                  <Heading as="h4">Accessibility</Heading>
                  <List gap="0" margin="0 0 0 2rem" as="ul">
                    <li>
                      <Anchor link="/legal/accessibility">Accessibility</Anchor>
                    </li>
                  </List>
                </Flex>
              </Grid>
            </Container>
          </Flex>
          <Flex>
            <Container text>
              <Paragraph>
                Let us know if you have questions, concerns, or ideas on how to
                make our products and services better.{" "}
                <Anchor link="mailto:info@klipfolio.com?subject=Security">
                  Let us know today.
                </Anchor>
              </Paragraph>
            </Container>
          </Flex>
        </Flex>
      </Container>
    </Layout>
  )
}

export default LegalPage
